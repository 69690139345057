import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';

// MUI's Component Library
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

const line_list = ['line_item_1', 'line_item_2', 'line_item_3'];
const recipe_list = ['recipe_item_1', 'recipe_item_2', 'recipe_item_3'];
const concept_list = ['concept_1', 'concept_2', 'concept_3'];

function MenuMapper(props: any) {
    const [concept, setConcept] = React.useState('');

    const handleConceptChange = (event: SelectChangeEvent) => {
        setConcept(event.target.value as string);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                alignContent: 'stretch',
                overflowX: 'hidden',
                overflowY: 'auto',
            }}
        >
            <FormControl fullWidth sx={{ width: '300px', m: 2 }}>
                <InputLabel id="demo-simple-select-label">Concept</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Concept"
                    value={concept}
                    onChange={handleConceptChange}
                >
                    {concept_list.map((concept, idx) => {
                        return (
                            <MenuItem value={concept} key={idx}>
                                {concept}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            {concept &&
                recipe_list.map((value, idx) => {
                    return (
                        <Box
                            width="100%"
                            display="flex"
                            sx={{
                                flexDirection: 'row',
                                alignItems: 'end',
                                m: 0.5,
                                mx: 2,
                                gap: 2,
                            }}
                        >
                            <Typography>{value}</Typography>
                            <ArrowForwardRoundedIcon />
                            <Box width="500px">
                                <Autocomplete
                                    multiple
                                    id="tags-standard"
                                    key={idx}
                                    options={line_list}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Line Items"
                                            placeholder="Example_Item_1"
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                    );
                })}
        </Box>
    );
}

export { MenuMapper };
