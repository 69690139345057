// No Data expected values
interface noDataInterface {
    message?: string;
    marginTop?: number | string;
    fontSize?: number | string;
    padding?: number | string;
}

// Relatively generic UI message component
function NoDataMessage(options?: Partial<noDataInterface>) {
    const defaultInterface = {
        message: '',
        marginTop: 0,
        fontSize: 27,
        padding: 0,
    };

    const { message, marginTop, fontSize, padding } = {
        ...defaultInterface,
        ...options,
    };

    return (
        <div
            style={{
                marginTop,
                textAlign: 'center',
                color: '#bfbfbf',
                padding,
                fontSize,
                width: '100%',
            }}
        >
            {message}
        </div>
    );
}

export default NoDataMessage;
