import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';

// MUI's Component Library
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { postJJUserOnboarding } from '../../../api/index';
import { Typography, Select, InputLabel, FormControl } from '@mui/material';

function OnboardingStatus(props: any) {
    const [requestObject, setRequestObject] = useState({});
    const [load, setLoad] = React.useState(false);
    const [response, setResponse] = React.useState<string | void>('');

    const modifyRequestObject = (key: any, value: any) => {
        let tempRequestObject: any = requestObject;
        tempRequestObject[key] = value;
        setRequestObject(tempRequestObject);
    };

    const checkKeys = (requestObject: any) => {
        const necessaryKeys = ['name', 'email', 'api_id', 'api_key'];
        necessaryKeys.map((key) => {
            if (!(key in requestObject)) {
                return false;
            }
        });
        return true;
    };

    const body = {
        user: {
            id: 'ji454b5 ij45ij45ij 45ib45ji45',
            email: '[patrick.wampler@gmail.com](mailto:patrick.wampler@gmail.com)',
            api_id: '0271D153-5F36-4462-B3EF-BCA6F1A0DF4B',
            api_key:
                '2B2D2129FDA2C4871D627B7048E9162F6C057A268F7053EA2C18CB5F79D8BE53D9EC56AF592FB2BEF8E9583E370D3E94702BC5A6C99B804E60115C6B8B3CCA61',
        },
    };

    return (
        <Box
            width="100%"
            height="100%"
            display="flex"
            sx={{ flexDirection: 'column' }}
        >
            <Box
                component="form"
                width="400px"
                margin="20px"
                noValidate
                autoComplete="off"
                sx={{ display: 'grid', gap: 2 }}
            >
                <FormControl>
                    <InputLabel id="demo-simple-select-label">
                        Concept
                    </InputLabel>
                    <Select id="Concept" label="Concept" value={null} />
                </FormControl>
                <FormControl disabled>
                    <InputLabel id="demo-simple-select-label">
                        Onboarding State
                    </InputLabel>
                    <Select
                        id="OnboardingState"
                        label="Onboarding State"
                        disabled
                        value={null}
                    />
                </FormControl>
                <Typography>The currently selected concept is in .</Typography>
            </Box>

            <Box margin="0 20px">
                <Button
                    disabled={load}
                    variant="contained"
                    onClick={() => {
                        setLoad(true);
                        if (checkKeys(requestObject)) {
                            setRequestObject(requestObject);
                            let rand_id = uuid();
                            modifyRequestObject('id', rand_id);
                            postJJUserOnboarding({ user: requestObject }).then(
                                (value) => {
                                    setResponse(value);
                                    console.log(response);
                                }
                            );
                        } else {
                            // send message
                        }
                        setLoad(false);
                    }}
                >
                    Submit
                </Button>
                <Typography> {response} </Typography>
            </Box>
        </Box>
    );
}

export { OnboardingStatus };
