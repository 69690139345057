import { Box } from "@mui/material"
import { IStores } from ".."
import './monitor.css';

import Stores from "./Stores";
import { getStatusColor } from "..";

export default function Concept(props: { conceptName: string, stores: IStores }) {
  // find max number of status in stores
  function findMaxofStores(stores: IStores) {
    let absMax = -1;
    for (const storeName in stores) {
      const store = stores[storeName];
      absMax = Math.max(
        store.fetch_status,
        store.processed_status,
        store.forecasts_status,
        store.productionize_status,
        store.accuracy_status,
        absMax
      );
    }
    return absMax;
  }
  // render concept header, and table contents
  return (<Box className="concept-container">
    <Box className="table-container">
      <Box className="concept-pill" style={{ backgroundColor: getStatusColor(findMaxofStores(props.stores)) }}>
        {props.conceptName}
      </Box>
      <Stores stores={props.stores} />
    </Box>
  </Box >
  )
}
