export const RoutesURL = {
    dashboard: '/dashboard',
    onboarding: '/onboarding',
    signup: '/signup',
    recipes: '/recipes',
    emails: '/emails',
    menuMapper: '/menu_mapper',
    notFound: '/*',
    monitorboard: '/monitorboard',
    onboardingstatus: '/onboardingstatus',
    filedownloader: '/filedownloader',
};
