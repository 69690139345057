import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import './monitor.css';
import { IConcepts } from '../index';
import Concepts from './Concepts';
import { fetchData } from '../api/api';

function Monitor() {
    const [concepts, setConcepts] = useState<IConcepts | null>(null);
    //fetch data from API
    useEffect(() => {
        const getData = async () => {
            const data = await fetchData();
            setConcepts(data);
        };

        getData();
    }, []);

    return (<Box className="monitor-container">{concepts ? <Concepts concepts={concepts} /> : 'Loading . . .'}</Box>)
}

export { Monitor };

