import { IStores } from "..";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import Store from "./Store";
import './monitor.css';
//render table header and table body
export default function Stores(props: { stores: IStores }) {
  return (<TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Fetch</TableCell>
          <TableCell>Process</TableCell>
          <TableCell>Forecast</TableCell>
          <TableCell>Production</TableCell>
          <TableCell>Accuracy</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(props.stores).map((storeName, storeIndex) => <Store key={storeIndex} storeName={storeName} storeStatus={props.stores[storeName]} />)}
      </TableBody>
    </Table>
  </TableContainer>)
}