// Standard MUI Components and Icons
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
//import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import { useNavigate } from 'react-router-dom';

// ? Warning: This currently does not work as intended.
// ?          Reminder: there is no functioning router.
// ?          This is simply a placeholder.
// Component that looks like a fancy navigation bar.
export function MainListItems() {
    let navigator = useNavigate();
    return (
        <List>
            <ListItem>
                <ListItemButton
                    onClick={() => {
                        navigator('/dashboard');
                    }}
                >
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Today's Stats" />
                </ListItemButton>
            </ListItem>
            <ListItem>
                <ListItemButton
                    onClick={() => {
                        navigator('/onboarding');
                    }}
                >
                    <ListItemIcon>
                        <AddBusinessIcon />
                    </ListItemIcon>
                    <ListItemText primary="Stores" />
                </ListItemButton>
            </ListItem>
            <ListItem>
                <ListItemButton
                    onClick={() => {
                        navigator('/signup');
                    }}
                >
                    <ListItemIcon>
                        <PersonAddAltRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="User Onboarding" />
                </ListItemButton>
            </ListItem>
            {/*<ListItem>
                <ListItemButton
                    onClick={() => {
                        navigator('/recipes');
                    }}
                >
                    <ListItemIcon>
                        <MenuBookRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Recipe Editor" />
                </ListItemButton>
                </ListItem>*/}
            <ListItem>
                <ListItemButton
                    onClick={() => {
                        navigator('/emails');
                    }}
                >
                    <ListItemIcon>
                        <EmailRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Email Preview" />
                </ListItemButton>
            </ListItem>
            <ListItem>
                <ListItemButton
                    onClick={() => {
                        navigator('/menu_mapper');
                    }}
                >
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Menu Mapper" />
                </ListItemButton>
            </ListItem>
            <ListItem>
                <ListItemButton
                    onClick={() => {
                        navigator('/monitorboard');
                    }}
                >
                    <ListItemIcon>
                        <PendingActionsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Monitoring Board" />
                </ListItemButton>
            </ListItem>
            <ListItem>
                <ListItemButton
                    onClick={() => {
                        navigator('/onboardingstatus');
                    }}
                >
                    <ListItemIcon>
                        <PendingActionsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Onboarding Status" />
                </ListItemButton>
            </ListItem>
            <ListItem>
                <ListItemButton
                    onClick={() => {
                        navigator('/filedownloader');
                    }}
                >
                    <ListItemIcon>
                        <AttachFileRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="File Downloader" />
                </ListItemButton>
            </ListItem>
        </List>
    );
}
