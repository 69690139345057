import React, { useEffect, useState } from 'react';

// MUI's Component Library
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { fetchFile, getDirectory } from '../../../api/index';
import {
    Select,
    InputLabel,
    FormControl,
    MenuItem,
    SelectChangeEvent,
    Typography,
} from '@mui/material';

const availableFileTypes = [
    'raw_detailed',
    'pretty_detailed',
    'raw_reversed',
    'pretty_reversed',
];

let today = new Date();
let tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);
let dd = String(today.getDate()).padStart(2, '0');
let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
let yyyy = today.getFullYear();
let dd_2 = String(tomorrow.getDate()).padStart(2, '0');
let mm_2 = String(tomorrow.getMonth() + 1).padStart(2, '0'); //January is 0!
let yyyy_2 = tomorrow.getFullYear();
let today_string = yyyy + '-' + mm + '-' + dd;
let tomorrow_string = yyyy_2 + '-' + mm_2 + '-' + dd_2;

function FileDownloader(props: any) {
    const [requestObject, setRequestObject] = useState({});
    const [load, setLoad] = React.useState(false);
    const [response, setResponse] = React.useState<string | void>('');

    const [hide, setHide] = React.useState<boolean>(false);

    const [directory, setDirectory] = useState<any>({});

    const [file, setFile] = React.useState<any>('');
    const [error, setError] = React.useState<any>('');
    const [concept, setConcept] = React.useState<string>('');
    const [store, setStore] = React.useState<string>('');
    const [date, setDate] = React.useState<string>(today_string);
    const [fileType, setFileType] = React.useState<string>('');

    const [gridCols, setGridCols] = React.useState<any>(undefined);
    const [gridRows, setGridRows] = React.useState<any>(undefined);

    useEffect(() => {
        setDirectory({});
        getDirectory(today_string).then((results) => {
            setDirectory(results);
            console.log(results);
        });
    }, []);

    useEffect(() => {
        let results = file.split('\n');
        let columns = results[0].split(',');
        let rows = results.slice(1).map((row: string) => row.split(','));

        console.log('DEBUGG:: ', columns, rows);

        let gridColsNew = columns.map((column: string) => {
            return {
                field: column,
                headerName: column,
                width: 150,
            };
        });
        console.log('GridCols:: ', gridColsNew);
        let gridRowsNew = rows.map((row: string[], idx: number) => {
            return columns.reduce(
                (acc: any, col: string, index: number) => {
                    acc[col] = row[index];
                    return acc;
                },
                { id: idx }
            );
        });
        console.log('GridRows:: ', gridRowsNew);

        setGridCols(gridColsNew);
        setGridRows(gridRowsNew);
    }, [file]);

    useEffect(() => {
        if (!!concept && !!store && !!date && !!fileType) {
            fetchFile(concept, store, date, fileType).then((results) => {
                console.log(results);
                if (
                    typeof results == 'string' &&
                    results.includes('file found at')
                ) {
                    setError(results);
                } else if (results == undefined) {
                    setError("Shik's fucted :)");
                } else {
                    setFile(results);
                }
            });
        }
    }, [concept, store, date, fileType]);

    const handleConceptChange = (event: SelectChangeEvent) => {
        setFile('');
        setError('');
        setStore('');
        setConcept(event.target.value as string);
    };
    const handleStoreChange = (event: SelectChangeEvent) => {
        setFile('');
        setError('');
        setStore(event.target.value as string);
    };
    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFile('');
        setError('');
        setDate(event.target.value);
    };
    const handleFileTypeChange = (event: SelectChangeEvent) => {
        setFile('');
        setError('');
        setFileType(event.target.value as string);
    };

    return (
        <Box
            width="100%"
            height="100%"
            display="flex"
            sx={{ flexDirection: 'column' }}
        >
            <Box
                component="form"
                width="400px"
                height={hide ? '0px' : 'default'}
                overflow={hide ? 'hidden' : 'default'}
                margin="20px"
                noValidate
                autoComplete="off"
                sx={{ display: 'grid', gap: 2 }}
            >
                <FormControl>
                    <InputLabel id="demo-simple-select-label">
                        Concept
                    </InputLabel>
                    <Select
                        id="Concept"
                        label="Concept"
                        value={concept}
                        onChange={handleConceptChange}
                    >
                        {Object.keys(directory)
                            .sort((string1, string2) => {
                                let a = string1.toLowerCase();
                                let b = string2.toLowerCase();

                                if (a < b) {
                                    return -1;
                                } else if (a > b) {
                                    return 1;
                                } else {
                                    return 0;
                                }
                            })
                            .map((conceptOption, idx) => {
                                return (
                                    <MenuItem value={conceptOption} key={idx}>
                                        {conceptOption}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>
                <FormControl disabled>
                    <InputLabel id="demo-simple-select-label">Store</InputLabel>
                    <Select
                        id="Store"
                        label="Store"
                        disabled={!concept}
                        value={store}
                        onChange={handleStoreChange}
                    >
                        {concept &&
                            Object.keys(directory[concept])
                                .sort((string1, string2) => {
                                    let a = string1.toLowerCase();
                                    let b = string2.toLowerCase();

                                    if (a < b) {
                                        return -1;
                                    } else if (a > b) {
                                        return 1;
                                    } else {
                                        return 0;
                                    }
                                })
                                .map((storeOption, idx) => {
                                    return (
                                        <MenuItem
                                            value={storeOption.replace(
                                                '_prep',
                                                ''
                                            )}
                                            key={idx}
                                        >
                                            {storeOption.replace('_prep', '')}
                                        </MenuItem>
                                    );
                                })}
                    </Select>
                </FormControl>
                <TextField
                    id="date"
                    label="Date"
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleDateChange}
                />
                <FormControl>
                    <InputLabel id="demo-simple-select-label">
                        File Type
                    </InputLabel>
                    <Select
                        id="File_Type"
                        label="File Type"
                        value={fileType}
                        onChange={handleFileTypeChange}
                    >
                        {availableFileTypes.map((fileTypeOption, idx) => {
                            return (
                                <MenuItem value={fileTypeOption} key={idx}>
                                    {fileTypeOption}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>

            <Button
                onClick={() => {
                    setHide(!hide);
                }}
            >
                Hide Controls
            </Button>

            <Box margin="0 20px">
                {/* {file && (
                    <CSVLink
                        data={file}
                        filename={`${concept}_${store}_${date}_${fileType}.csv`}
                        className="btn btn-primary"
                        target="_blank"
                    >
                        Download
                    </CSVLink>
                )} */}
                {error ? (
                    <Typography>{error}</Typography>
                ) : (
                    file &&
                    gridRows &&
                    gridCols && (
                        <Box
                            sx={{
                                height: hide ? 550 : 300,
                                width: '100%',
                            }}
                        >
                            <DataGrid
                                rows={gridRows}
                                columns={gridCols}
                                slots={{
                                    toolbar: GridToolbar,
                                }}
                                slotProps={{
                                    toolbar: {
                                        csvOptions: {
                                            fileName: `${concept}_${store}_${date}`,
                                        },
                                    },
                                }}
                            />
                        </Box>
                    )
                )}
            </Box>
        </Box>
    );
}

export { FileDownloader };
