// React's native responsive state machine subfunction
import { useEffect, useState } from 'react';

// Simplified Query Object, API functions, and user context
//import { useQuery } from 'react-query';
//import { getUser } from './api';
import { useSetUser, useUserState } from './contexts/newUserContext';

// Imported AWS interfaces
import { CognitoUserAmplify } from '@aws-amplify/ui';

// Primary application rendering components
// * Application 1st layer of render
import Layout from './components/Layout';
// * Application 2nd layer of render
import { AppRouter } from './AppRouter';
// * Application 1st layer catch-error
//import NoDataMessage from './components/NoDataMessage';

// ! Security flaw: We should not have uuids or auth keys
// !                in plaintext! (Keeping for debugging)
// C2AB6F62-1711-4C85-AF25-57CCA819C55F

// Defines values to be passed to App via properties
interface appProps {
    user: CognitoUserAmplify;
}

// ? Warning: Non-robust use of <any> datatypes
// ?          @param {TData} data
// ?          @param {TError} error
// Defines values to be expected from GET user
interface getUserResponce {
    isLoading: boolean;
    isError: boolean;
    data: any;
    error: any;
}

// Outermost shell of core application post-authentication
function App(props: appProps) {
    // Establishes User context from external files and API
    const setUser = useSetUser();
    //const u = useUserState();
    //const [store, setStore] = useState(null);
    /*const { isLoading, isError, data, error }: getUserResponce = useQuery(
        ['user', props.user!.attributes!.sub],
        getUser,
        { staleTime: 300000 }
    );*/

    const { isLoading, isError, data, error } = {
        isLoading: false,
        isError: false,
        data: null,
        error: false,
    };

    /*let s3 = new AWS.S3({ apiVersion: '2006-03-01' });

    var bucketParams = {
        Bucket: 'boloco-data',
    };

    s3.listObjects(bucketParams, function (err, data) {
        if (err) {
            console.log('Error', err);
        } else {
            console.log('Success', data);
        }
    });*/

    // Mounts a listener for a change in data
    useEffect(() => {
        if (data) {
            setUser(data);
        }
    }, [data, setUser]);

    // Renders a fullscreen "Loading" message while waiting for data
    /*if ((!isError && isLoading) || !u) {
        return <NoDataMessage message="Loading . . ." marginTop={100} />;
    }*/

    // Renders a error code/message on data failure
    /*if (isError) {
        return <span>Error: {error.message}</span>;
    }*/

    // Sets the store code to the first uuid inside the user object
    /*if (!store) {
        setStore(u.stores[0]);
    }*/

    // Renders the Layout (1st Layer) -> Admin (2nd Layer)
    return (
        <Layout>
            <AppRouter />
        </Layout>
    );
}

// Exports for use by `./index.tsx`
export default App;
