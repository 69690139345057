// ! Security flaw: URLs should be stored in enviromental
// !                files or secret managers!
// Establishes the middleware exicution location
const baseUrl = 'https://r0qqwiem7g.execute-api.us-east-2.amazonaws.com/dev';

/**
 * * Async JSON request
 * @param {null}
 * @returns {object} object containing all user and store data
 */
export async function fetchFile(
    concept: string,
    store: string,
    date: string,
    fileType: string
) {
    const url = `${baseUrl}/file_fetcher`;
    let body = JSON.stringify({
        action: 'get_usage',
        concept: concept,
        store: store,
        date: date,
        type: fileType,
    });

    return fetch(url, {
        method: 'Post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: body,
    })
        .then((response) => {
            console.log(response);
            return response.text();
        })
        .catch((error) => {
            console.error(error);
            return error.json();
        });
}
