// React
import React from 'react';

// Standard React object control and caching tools
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

// Imports post-auth application
import App from './App';

// Provides User Context
import { UserProvider } from './contexts/newUserContext';

// Frontend authentication components
import Amplify from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';

// Established backend query interpeter
import { QueryClient, QueryClientProvider } from 'react-query';
const queryClient = new QueryClient();

// Configures AWS Frontend deployment connection
Amplify.configure(awsconfig);

// gets the container in which the app will be rendered
const container = document.getElementById('root')!;
const root = createRoot(container);

// ! Security flaw: The external router implies that the user
// !                may be able to navigate away from the auth
// !                assuming the implimentation of new pages!
// !                Router should be within App!
// ? Warning: Router should re-route all unknown extentions to
// ?          `/` and `/index` to default stable page management,
// ?          or re-route to `/404` to provide the user with
// ?          context to their failed extention path. No router
// ?          scheme has been established yet. All paths default
// ?          the Admin view.
// TODO: Modify the Router and Provide position inside the Auth
// Establishes a Object Model & Router
// Renders the application within an auth component
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <BrowserRouter>
                    <UserProvider>
                        <Authenticator>
                            {({ user }) => user && <App user={user} />}
                        </Authenticator>
                    </UserProvider>
                </BrowserRouter>
            </LocalizationProvider>
        </QueryClientProvider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint.
reportWebVitals();
