const baseUrl = 'https://r0qqwiem7g.execute-api.us-east-2.amazonaws.com/dev';

export async function postJJUserOnboarding(object: any) {
    const url = `${baseUrl}/jj-add-user`;
    let body = JSON.stringify(object);
    console.log(body);

    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: body,
    })
        .then(response => {
            return response.text();
        })
        .catch(error => {
            console.error(error);
        });
}