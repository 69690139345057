// JJDash Filter 1: Waste
const maxWaste = 4;
const minWaste = 1;

// JJDash Filter 2: Stock Out
const maxStockOut = 10;
const minStockOut = 0;

// JJDash Filter 3: Unused Wheat
const maxUnusedWheat = 3;
const minUnusedWheat = 1;

// JJDash Filter 4: Sales Upper
const maxSalesUpper = 5000;
const minSalesUpper = 3000;

export {
    maxWaste,
    minWaste,
    maxStockOut,
    minStockOut,
    maxUnusedWheat,
    minUnusedWheat,
    maxSalesUpper,
    minSalesUpper,
};
