import { IStoreStatus } from "..";
import { TableCell, TableRow, Tooltip } from "@mui/material";
import { getStatusColor } from "..";
import './monitor.css';
//render store row containing 5 status cells
export default function Store(props: { storeName: string, storeStatus: IStoreStatus, }) {
  return (<TableRow hover
    role="checkbox"
    tabIndex={-1}
  >
    <TableCell>{props.storeName}</TableCell>
    <Tooltip title={props.storeStatus.fetch_message} >
      <TableCell style={{ backgroundColor: getStatusColor(props.storeStatus.fetch_status) }}></TableCell>
    </Tooltip>
    <Tooltip title={props.storeStatus.processed_message} >
      <TableCell style={{ backgroundColor: getStatusColor(props.storeStatus.processed_status) }}></TableCell>
    </Tooltip>
    <Tooltip title={props.storeStatus.forecasts_message}>
      <TableCell style={{ backgroundColor: getStatusColor(props.storeStatus.forecasts_status) }}></TableCell>
    </Tooltip>
    <Tooltip title={props.storeStatus.productionize_message}>
      <TableCell style={{ backgroundColor: getStatusColor(props.storeStatus.productionize_status) }}></TableCell>
    </Tooltip>
    <Tooltip title={props.storeStatus.accuracy_message}>
      <TableCell style={{ backgroundColor: getStatusColor(props.storeStatus.accuracy_status) }}></TableCell>
    </Tooltip>
  </TableRow>)
}