import React, { useState, useEffect, useCallback } from 'react';

// MUI's Component Library
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { postJjdashOnboarding } from '../../../api/index';

function Onboarding(props: any) {
    const [requestObject, setResponse] = useState({});

    const modifyRequest = useCallback(
        (key: string, value: any) => {
            var responseNew: any = requestObject;
            responseNew[key] = value;
            setResponse(responseNew);
        },
        [requestObject]
    );

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        modifyRequest(event.target.id, event.target.value);
    };

    useEffect(() => {
        'fields' in props.store.onboarding.current &&
            props.store.onboarding.current.fields.map((field: any) => {
                modifyRequest(field.fieldname, field.value);
                return 0;
            });
        !('fields' in props.store.onboarding.current) &&
            modifyRequest('id', props.store.id);
        modifyRequest(
            'action',
            props.store.onboarding.stages[
                props.store.onboarding.current.stage + 1
            ]
        );
    }, [modifyRequest, requestObject, props.store]);

    return (
        <Box
            width="100%"
            height="100%"
            display="flex"
            sx={{ flexDirection: 'column' }}
        >
            <Box margin="20px">
                <Typography variant="h3">{props.store.name}</Typography>
            </Box>
            <Box margin="0 20px">
                <Typography variant="h6">
                    {'Results from Step '}
                    {props.store.onboarding.current.stage}
                </Typography>
            </Box>
            <Box
                component="form"
                width="fill"
                height="fill"
                margin="20px"
                noValidate
                autoComplete="off"
                sx={{ display: 'grid', gap: 2 }}
            >
                <Box
                    id="Results"
                    width="fill"
                    height="fill"
                    sx={{ overflow: 'scroll' }}
                    dangerouslySetInnerHTML={{
                        __html: props.store.onboarding.current.results,
                    }}
                />
            </Box>
            <Box margin="0 20px">
                <Typography variant="h6">
                    {'Step '} {props.store.onboarding.current.stage + 1}
                    {': '}
                    {
                        props.store.onboarding.stages[
                            props.store.onboarding.current.stage + 1
                        ]
                    }
                </Typography>
            </Box>
            {'fields' in props.store.onboarding.current && (
                <Box
                    component="form"
                    width="400px"
                    margin="20px"
                    noValidate
                    autoComplete="off"
                    sx={{ display: 'grid', gap: 2 }}
                >
                    {props.store.onboarding.current.fields.map(
                        (field: any, index: number) => {
                            return (
                                <TextField
                                    key={index}
                                    required
                                    id={field.fieldname}
                                    label={field.fieldname}
                                    defaultValue={field.value}
                                    value={
                                        requestObject[
                                            field.fieldname as keyof typeof requestObject
                                        ]
                                    }
                                    onChange={handleTextChange}
                                    InputProps={{
                                        readOnly: field.lock,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            );
                        }
                    )}
                </Box>
            )}
            <Box margin="0 20px">
                <Button
                    variant="contained"
                    onClick={() => {
                        postJjdashOnboarding(requestObject);
                        props.setSelected(null);
                    }}
                    disabled={props.store.onboarding.progress < 100}
                >
                    Continue
                </Button>
                <Button
                    onClick={() => {
                        props.setSelected(null);
                    }}
                >
                    Return
                </Button>
            </Box>
        </Box>
    );
}

export { Onboarding };
