// React standard state management tools
import { createContext, useState, useContext } from 'react';

// Expected values kept in context
interface AppContextInterface {
    user: any;
    setUser: any;
}

// Establishes parent-child state managers
const UserStateContext = createContext<AppContextInterface | null>(null);

// Children of this component share the User state machine
function UserProvider({ children }: any) {
    // Establishes user state
    const [user, setUser] = useState(null);

    // A change uses `UserSetContext` to update `UserStateContext`
    // On failure, `UserStateContext` remains unchanged
    const setUserSafe = (val: any) => {
        if (!val) {
            throw new Error(
                'Setting user context value to undefined breaks user context'
            );
        }
        setUser(val);
    };

    const AppContext: AppContextInterface = {
        user: user,
        setUser: setUserSafe,
    };

    // Renders the multi-layer context
    return (
        <UserStateContext.Provider value={AppContext}>
            {children}
        </UserStateContext.Provider>
    );
}

// This function returns the context of the user state machine
function useUserState() {
    const context = useContext(UserStateContext);
    if (!context) {
        throw new Error('useUserState must be used with UserProvider');
    }
    return context.user;
}

// This function returns the context of the user state machine
function useSetUser() {
    const context = useContext(UserStateContext);
    if (!context) {
        throw new Error('useSetUser must be used with UserProvider');
    }
    return context.setUser;
}

// Exports for use by primary application render
export { UserProvider, useUserState, useSetUser };
