export {Monitor} from "./components/Monitor"

export interface IConcepts {
  [key: string]: IStores;
}

export interface IStores {
  [key: string]: IStoreStatus;
}

export interface IStoreStatus {
  fetch_status: number;
  processed_status: number;
  forecasts_status: number;
  productionize_status: number;
  accuracy_status: number;
  fetch_message: string;
  processed_message: string;
  forecasts_message: string;
  productionize_message: string;
  accuracy_message: string;
}
 export function getStatusColor(status: number): string {
  switch (status) {
    case 0:
      return '#48c76e';
    case 1:
      return '#fdff84';
    case 2:
      return '#ff9360';
    case 3:
      return '#fb4e4e';
    default:
      return '';
  }
}
