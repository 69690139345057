// MUI's Component Library
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import LinearProgress, {
    LinearProgressProps,
} from '@mui/material/LinearProgress';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material/styles';

function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
) {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography
                    variant="body2"
                    color="text.secondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    variant: 'outlined',
}));

function StoreWidget(props: any) {
    return (
        <Item>
            <Box
                display="flex"
                sx={{
                    flexDireaction: 'row',
                    justifyContent: 'flex-start',
                }}
                onClick={() => {
                    'onboarding' in props.store &&
                        props.setSelected(props.store.id);
                }}
            >
                <Box width="280px">
                    <Typography variant="h6">{props.store.name}</Typography>
                </Box>

                <Box
                    width="80px"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignContent: 'center',
                    }}
                >
                    {'onboarding' in props.store ? (
                        <RadioButtonUncheckedIcon />
                    ) : (
                        <CheckCircleIcon />
                    )}
                </Box>
                <Box
                    width="160px"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignContent: 'center',
                    }}
                >
                    {'onboarding' in props.store ? (
                        <Typography variant="h6">
                            {props.store.onboarding.current.stage + 1}
                            {' / '}
                            {props.store.onboarding.stages.length}
                        </Typography>
                    ) : (
                        <Box />
                    )}
                </Box>
                <Box width="340px" padding="0px 15px" margin="auto 0">
                    {'onboarding' in props.store ? (
                        <LinearProgressWithLabel
                            value={props.store.onboarding.progress}
                        />
                    ) : (
                        <Box />
                    )}
                </Box>
            </Box>
        </Item>
    );
}

export { StoreWidget };
