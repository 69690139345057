import React from 'react';

// Standard MUI Components and Styling
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

// Custom rendering components
// * Application 2nd layer of render
import SideBar from './SideBar';

// Inline styles object
const styles = {
    root: {
        display: 'flex',
        height: '100vh',
        alignItems: 'stretch',
    },
    content: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'stretch',
        overflow: 'auto',
    },
};

// Expected value passed to Layout
interface layoutInterface {
    children: JSX.Element;
}

// Standard flexbox layout tool defining a primary area and a sidebar
export default function Layout({ children }: layoutInterface) {
    // Establishes sidebar state machine
    const [open, setOpen] = React.useState(true);
    // Sidebar state handler
    const handleDrawerClose = () => setOpen(!open);

    // Renders sidebar alongside main area
    return (
        <Box sx={styles.root}>
            <CssBaseline />
            <SideBar open={open} handleDrawerClose={handleDrawerClose} />
            <Box sx={styles.content}>
                <main style={styles.content}>{children}</main>
            </Box>
        </Box>
    );
}
