// React's native responsive state machine subfunction
import React, { useEffect, useState } from 'react';

// Simplified Query Object and API functions
import { useQuery } from 'react-query';
import { getPullStores } from './../../../api/index';

// MUI's Component Library
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

// External Components
import { StoreWidget } from './StoreWidget';
import { Onboarding } from './../../Onboarding';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, 0)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
}));

// ? Warning: Non-robust use of <any> datatypes
// ?          @param {TData} data
// ?          @param {TError} error
// Defines values to be expected from GET user
interface getResponce {
    isLoading: boolean;
    isError: boolean;
    data: any;
    error: any;
}

function Stores(props: any) {
    const [stores, setStores] = useState<any | null>(null);
    const [selected, setSelected] = useState<any | null>(null);
    const [expanded, setExpanded] = useState<string | false>('panel2');
    const [pagesOnboarded, setPagesOnboarded] = useState<number>(0);
    const [pagesOnboarding, setPagesOnboarding] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [speedDialOpen, setSpeedDialOpen] = useState<boolean>(false);
    const widgetHeight = 60;
    const widgetLimit = Math.floor(window.innerHeight / widgetHeight) - 4;

    const handleSpeedDialOpen = () => setSpeedDialOpen(true);
    const handleSpeedDialClose = () => setSpeedDialOpen(false);

    const handleAccordionChange =
        (panel: string) =>
        (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
            setPage(1);
        };

    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setPage(value);
    };

    const handleActionRefresh = (event: React.ChangeEvent<unknown>) => {
        handleSpeedDialClose();
        getPullStores().then((response) => {
            setStores(response);
        });
    };

    const actions = [
        {
            icon: <RefreshIcon />,
            name: 'Refresh',
            action: handleActionRefresh,
        },
    ];

    const { /*isLoading, isError,*/ data /*error*/ }: getResponce = useQuery(
        [],
        getPullStores,
        {
            staleTime: 300000,
        }
    );

    useEffect(() => {
        if (data) {
            setStores(data);
            setPagesOnboarded(
                Math.ceil(
                    data.filter((store: any) => !('onboarding' in store))
                        .length / widgetLimit
                )
            );
            setPagesOnboarding(
                Math.ceil(
                    data.filter((store: any) => 'onboarding' in store).length /
                        widgetLimit
                )
            );
        }
    }, [data, setStores, widgetLimit]);

    return (
        <Box width="100%" height="100%">
            {stores ? (
                selected !== null ? (
                    <Onboarding
                        store={stores.find((x: any) => x.id === selected)}
                        setSelected={setSelected}
                    />
                ) : (
                    <Box margin="20px">
                        <Accordion
                            expanded={expanded === 'panel1'}
                            onChange={handleAccordionChange('panel1')}
                        >
                            <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1a-header"
                            >
                                <Typography>
                                    Live{' '}
                                    {`(${
                                        stores.filter(
                                            (store: any) =>
                                                !('onboarding' in store)
                                        ).length
                                    })`}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" spacing={2}>
                                    {stores
                                        .filter(
                                            (store: any) =>
                                                !('onboarding' in store)
                                        )
                                        .slice(
                                            (page - 1) * widgetLimit,
                                            page * widgetLimit
                                        )
                                        .map((store: any, index: number) => (
                                            <StoreWidget
                                                key={index}
                                                index={index}
                                                store={store}
                                                setSelected={setSelected}
                                            />
                                        ))}
                                    {pagesOnboarded > 1 && (
                                        <Pagination
                                            count={pagesOnboarded}
                                            page={page}
                                            onChange={handlePageChange}
                                        />
                                    )}
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === 'panel2'}
                            onChange={handleAccordionChange('panel2')}
                        >
                            <AccordionSummary
                                aria-controls="panel2d-content"
                                id="panel2a-header"
                            >
                                <Typography>
                                    Onboarding{' '}
                                    {`(${
                                        stores.filter(
                                            (store: any) =>
                                                'onboarding' in store
                                        ).length
                                    })`}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" spacing={2}>
                                    {stores
                                        .filter(
                                            (store: any) =>
                                                'onboarding' in store
                                        )
                                        .slice(
                                            (page - 1) * widgetLimit,
                                            page * widgetLimit
                                        )
                                        .map((store: any, index: number) => (
                                            <StoreWidget
                                                key={index}
                                                index={index}
                                                store={store}
                                                setSelected={setSelected}
                                            />
                                        ))}
                                    {pagesOnboarding > 1 && (
                                        <Pagination
                                            count={pagesOnboarding}
                                            page={page}
                                            onChange={handlePageChange}
                                        />
                                    )}
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                )
            ) : (
                <Box margin="20px">
                    <Typography variant="h6"> Loading... </Typography>
                </Box>
            )}
            <SpeedDial
                ariaLabel="SpeedDial basic example"
                sx={{ position: 'absolute', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
                onClose={handleSpeedDialClose}
                onOpen={handleSpeedDialOpen}
                open={speedDialOpen}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={action.action}
                    />
                ))}
            </SpeedDial>
        </Box>
    );
}

export { Stores };
