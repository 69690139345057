// ! Security flaw: URLs should be stored in enviromental
// !                files or secret managers!
// Establishes the middleware exicution location
const baseUrl = 'https://r0qqwiem7g.execute-api.us-east-2.amazonaws.com/dev';

/**
 * * Async JSON request
 * @param {object}
 * @returns {object} object containing all user and store data
 */
export async function postJjdashOnboarding(object: any) {
    const url = `${baseUrl}/jjdash_onboarding`;
    let body = JSON.stringify(object);

    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: body,
    })
        .then(response => {
            return response.json();
        })
        .catch(error => {
            console.error(error);
        });
}
