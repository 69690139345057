// ! Security flaw: URLs should be stored in enviromental
// !                files or secret managers!
// Establishes the middleware exicution location
const baseUrl = 'https://r0qqwiem7g.execute-api.us-east-2.amazonaws.com/dev';

/**
 * * Async JSON request
 * @param {null}
 * @returns {object} object containing all user and store data
 */
export async function getDirectory(date: string) {
    const url = `${baseUrl}/email_preview?action=LIST&date=${date}`;

    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            console.error(error);
        });
}
