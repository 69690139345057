import { useQuery } from 'react-query';
import { useState, useEffect, useCallback, useMemo } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ButtonGroup } from '@aws-amplify/ui-react';
//import { Filter } from '@mui/icons-material';

import NoDataMessage from '../../../components/NoDataMessage';
import { columnsJJ } from './Columns';
import {
    maxWaste,
    minWaste,
    maxStockOut,
    minStockOut,
    maxUnusedWheat,
    minUnusedWheat,
    maxSalesUpper,
    minSalesUpper,
} from './FilterThresholds';

// ? Warning: Non-robust use of <any> datatypes
// ?          @param {TData} data
// ?          @param {TError} error
// Defines values to be expected from GET stores
interface getStoresResponce {
    isLoading: boolean;
    isError: boolean;
    data: any;
    error: any;
}

// ! Security flaw: URLs should be stored in enviromental
// !                files or secret managers!
/**
 * * Async JSON request
 * @param {null}
 * @returns {object} object containing artifact data of all stores.
 */
async function getStores() {
    const url =
        'https://r0qqwiem7g.execute-api.us-east-2.amazonaws.com/dev/dynamodb-access';
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
}

// Primary component function
function Admin() {
    // Establishes display-data value state
    const [stores, setStores] = useState<any | null>(null);

    // Establishes filter status state
    const [open1, setOpen1] = useState<boolean>(false);
    const [open2, setOpen2] = useState<boolean>(false);
    const [open3, setOpen3] = useState<boolean>(false);
    const [open4, setOpen4] = useState<boolean>(false);

    // Creates a Memoized filter object
    const filterArray = useMemo(() => {
        return [
            {
                id: 1,
                title: 'Waste',
                pressed: [open1],
                onClick: (val: boolean) => setOpen1(val),
                filter: function(data: any) {
                    return data.filter(
                        (data: any) =>
                            data['Unused Trays'] > maxWaste ||
                            data['Unused Trays'] < minWaste
                    );
                },
            },
            {
                id: 2,
                title: 'Stock Out',
                pressed: [open2],
                onClick: (val: boolean) => setOpen2(val),
                filter: function(data: any) {
                    return data.filter(
                        (data: any) =>
                            data['Unused Sticks'] > maxStockOut ||
                            data['Unused Sticks'] < minStockOut
                    );
                },
            },
            {
                id: 3,
                title: 'Filter 3',
                pressed: [open3],
                onClick: (val: boolean) => setOpen3(val),
                filter: function(data: any) {
                    return data.filter(
                        (data: any) =>
                            data['Unused Wheat'] > maxUnusedWheat ||
                            data['Unused Wheat'] < minUnusedWheat
                    );
                },
            },
            {
                id: 4,
                title: 'Filter 4',
                pressed: [open4],
                onClick: (val: boolean) => setOpen4(val),
                filter: function(data: any) {
                    return data.filter(
                        (data: any) =>
                            data['Sales Upper'] > maxSalesUpper ||
                            data['Sales Upper'] < minSalesUpper
                    );
                },
            },
        ];
    }, [open1, open2, open3, open4]);

    // Fetches all stores' artifact data
    const { isLoading, isError, data, error }: getStoresResponce = useQuery(
        [],
        getStores,
        {
            staleTime: 300000,
        }
    );

    // Generates data object to be displayed
    const generateResults = useCallback(() => {
        var newData = data;
        filterArray.map((filterObj: any) => {
            filterObj.pressed[0] && (newData = filterObj.filter(newData));
            return 0;
        });
        setStores(newData);
    }, [data, filterArray, setStores]);

    // Generates data on filter change
    useEffect(() => {
        generateResults();
    }, [open1, open2, open3, open4, generateResults]);

    // Initially sets data on load
    useEffect(() => {
        data?.map((row: any, index: number) => (row.id = index));
        setStores(data);
    }, [data]);

    // Renders a "Loading" message while waiting for data on the 2nd layer
    if (!isError && isLoading) {
        return <NoDataMessage message="Loading . . ." marginTop={100} />;
    }

    // Renders a error code/message on data failure
    if (isError) {
        return <span>Error: {error.message}</span>;
    }

    // Grabs refactored columns object
    const columns = columnsJJ;

    // Renders the DataGrid/Admin Screen
    return (
        <Box
            width="100%"
            height="900px"
            sx={{
                height: 900,
                width: 1,
                display: 'flex',
                flexDirection: 'column',
                '& .super-app.negative': {
                    backgroundColor: 'red',
                    fontWeight: '600',
                },
                '& .super-app.positive': {
                    backgroundColor: 'red',
                    fontWeight: '600',
                },
            }}
        >
            <ButtonGroup
                style={{
                    margin: 5,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                }}
            >
                {filterArray.map((filter: any) => {
                    return (
                        <Button
                            key={filter.id}
                            variant={
                                filter.pressed[0] ? 'contained' : 'outlined'
                            }
                            sx={{ color: 'black', borderColor: 'black' }}
                            onClick={() => {
                                filter.onClick(!filter.pressed[0]);
                            }}
                        >
                            {filter.title}
                        </Button>
                    );
                })}
            </ButtonGroup>

            {stores && <DataGrid rows={stores} columns={columns} />}
        </Box>
    );
}

// Exports for indexing by './index.js
export default Admin;
