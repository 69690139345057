/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: 'us-east-2',
    aws_cognito_identity_pool_id:
        'us-east-2:b23de989-11db-4dd6-a85f-02c245197793',
    aws_cognito_region: 'us-east-2',
    aws_user_pools_id: 'us-east-2_iBsJeQRVy',
    aws_user_pools_web_client_id: '1nsk826gkoqt18a55ggh8jmhlm',
    oauth: {},
};

export default awsmobile;
