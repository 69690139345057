import clsx from 'clsx';

import {
    maxWaste,
    minWaste,
    maxStockOut,
    minStockOut,
} from './FilterThresholds';

const columnsJJ = [
    { field: 'Name', headerName: 'Name', type: 'number' },
    {
        field: 'Unused Trays',
        headerName: 'Unused Trays',
        type: 'number',
        cellClassName: (params: any) =>
            clsx('super-app', {
                negative: params.value < minWaste,
                positive: params.value > maxWaste,
            }),
    },
    { field: 'Unused Sticks', headerName: 'Unused Sticks', type: 'number',
        cellClassName: (params: any) =>
            clsx('super-app', {
                negative: params.value < minStockOut,
                positive: params.value > maxStockOut,
            }), 
    },
    { field: 'Unused Wheat', headerName: 'Unused Wheat', type: 'number' },
    {
        field: 'Sales Forecast',
        headerName: 'Sales Forecast',
        type: 'number',
    },
    { field: 'Sales Upper', headerName: 'Sales Upper', type: 'number' },
    { field: 'Sales Buffer', headerName: 'Sales Buffer', type: 'number' },
    {
        field: 'Sales Buffer %',
        headerName: 'Sales Buffer %',
        type: 'number',
    },
    { field: 'Sales Actual', headerName: 'Sales Actual', type: 'number' },
    {
        field: 'AM Sales Forecast',
        headerName: 'AM Sales Forecast',
        type: 'number',
    },
    {
        field: 'AM Sales Upper',
        headerName: 'AM Sales Upper',
        type: 'number',
    },
    {
        field: 'AM Sales Buffer',
        headerName: 'AM Sales Buffer',
        type: 'number',
    },
    {
        field: 'AM Sales Buffer %',
        headerName: 'AM Sales Buffer %',
        type: 'number',
    },
    {
        field: 'AM Sales Actual',
        headerName: 'AM Sales Actual',
        type: 'number',
    },
    {
        field: 'PM Sales Forecast',
        headerName: 'PM Sales Forecast',
        type: 'number',
    },
    {
        field: 'PM Sales Upper',
        headerName: 'PM Sales Upper',
        type: 'number',
    },
    {
        field: 'PM Sales Buffer',
        headerName: 'PM Sales Buffer',
        type: 'number',
    },
    {
        field: 'PM Sales Buffer %',
        headerName: 'PM Sales Buffer %',
        type: 'number',
    },
    {
        field: 'PM Sales Actual',
        headerName: 'PM Sales Actual',
        type: 'number',
    },
    {
        field: 'AM Bread Forecast',
        headerName: 'AM Bread Forecast',
        type: 'number',
    },
    {
        field: 'AM Bread Upper',
        headerName: 'AM Bread Upper',
        type: 'number',
    },
    {
        field: 'AM Bread Buffer',
        headerName: 'AM Bread Buffer',
        type: 'number',
    },
    {
        field: 'AM Bread Buffer %',
        headerName: 'AM Bread Buffer %',
        type: 'number',
    },
    {
        field: 'AM Bread Actual',
        headerName: 'AM Bread Actual',
        type: 'number',
    },
    {
        field: 'AM Sales/Bread',
        headerName: 'AM Sales/Bread',
        type: 'number',
    },
    {
        field: 'PM Bread Forecast',
        headerName: 'PM Bread Forecast',
        type: 'number',
    },
    {
        field: 'PM Bread Upper',
        headerName: 'PM Bread Upper',
        type: 'number',
    },
    {
        field: 'PM Bread Buffer',
        headerName: 'PM Bread Buffer',
        type: 'number',
    },
    {
        field: 'PM Bread Buffer %',
        headerName: 'PM Bread Buffer %',
        type: 'number',
    },
    {
        field: 'PM Bread Actual',
        headerName: 'PM Bread Actual',
        type: 'number',
    },
    {
        field: 'PM Sales/Bread',
        headerName: 'PM Sales/Bread',
        type: 'number',
    },
    {
        field: 'Last two Forecast',
        headerName: 'Last two Forecast',
        type: 'number',
    },
    {
        field: 'Last two Upper',
        headerName: 'Last two Upper',
        type: 'number',
    },
    {
        field: 'Last two Buffer',
        headerName: 'Last two Buffer',
        type: 'number',
    },
    {
        field: 'Last two Buffer %',
        headerName: 'Last two Buffer %',
        type: 'number',
    },
    {
        field: 'Last two actual',
        headerName: 'Last two Actual',
        type: 'number',
    },
    {
        field: 'Wheat Forecast',
        headerName: 'Wheat Forecast',
        type: 'number',
    },
    { field: 'Wheat Upper', headerName: 'Wheat Upper', type: 'number' },
    { field: 'Wheat Buffer', headerName: 'Wheat Buffer', type: 'number' },
    {
        field: 'Wheat Buffer %',
        headerName: 'Wheat Buffer %',
        type: 'number',
    },
    { field: 'Wheat Actual', headerName: 'Wheat Actual', type: 'number' },
    {
        field: 'Filtered Vs. Unfiltered Bread',
        headerName: 'Filtered Vs. Unfiltered Bread',
        type: 'number',
    },
    {
        field: 'Filtered Vs. Unfiltered Bread_z',
        headerName: 'Filtered Vs. Unfiltered Bread_z',
        type: 'number',
    },
    {
        field: 'Catering Vs. Unfiltered Bread',
        headerName: 'Catering Vs. Unfiltered Bread',
        type: 'number',
    },
    {
        field: 'Catering Vs. Unfiltered Bread_z',
        headerName: 'Catering Vs. Unfiltered Bread_z',
        type: 'number',
    },
    {
        field: 'Wheat Vs. French Bread',
        headerName: 'Wheat Vs. French Bread',
        type: 'number',
    },
    {
        field: 'Wheat Vs. French Bread_z',
        headerName: 'Wheat Vs. French Bread_z',
        type: 'number',
    },
    {
        field: 'Subtotal Vs. Wsr',
        headerName: 'Subtotal Vs. Wsr',
        type: 'number',
    },
    {
        field: 'Subtotal Vs. Wsr_z',
        headerName: 'Subtotal Vs. Wsr_z',
        type: 'number',
    },
    {
        field: 'Bread Amount Vs. Subtotal',
        headerName: 'Bread Amount Vs. Subtotal',
        type: 'number',
    },
    {
        field: 'Bread Amount Vs. Subtotal_z',
        headerName: 'Bread Amount Vs. Subtotal_z',
        type: 'number',
    },
    {
        field: 'Bread Subtotal Vs. Bread Units',
        headerName: 'Subtotal Vs. Bread Units',
        type: 'number',
    },
    {
        field: 'Bread Subtotal Vs. Bread Units_z',
        headerName: 'Subtotal Vs. Bread Units_z',
        type: 'number',
    },
    {
        field: 'Wsr Vs. Bread Units',
        headerName: 'Wsr Vs. Bread Units',
        type: 'number',
    },
    {
        field: 'Wsr Vs. Bread Units_z',
        headerName: 'Wsr Vs. Bread Units_z',
        type: 'number',
    },
    {
        field: 'Bread Subtotal Vs. Bread Units (no catering)',
        headerName: 'Subtotal Vs. Bread Units (no catering)',
        type: 'number',
    },
    {
        field: 'Bread Subtotal Vs. Bread Units (no catering)_z',
        headerName: 'Subtotal Vs. Bread Units (no catering)_z',
        type: 'number',
    },
    {
        field: 'Non Catering Tray Values',
        headerName: 'Non-catering Tray Values',
        type: 'number',
    },
    {
        field: 'Non Catering Tray Values_z',
        headerName: 'Non-catering Tray Values_z',
        type: 'number',
    },
    {
        field: 'Bread Subtotal Vs. Bread Units',
        headerName: 'Bread Subtotal Vs. Bread Units',
        type: 'number',
    },
    {
        field: 'Bread Subtotal Vs. Bread Units_z',
        headerName: 'Bread Subtotal Vs. Bread Units_z',
        type: 'number',
    },
    {
        field: 'Sandwich Count Vs. Ticket Count',
        headerName: 'Sandwich Count Vs. Ticket Count',
        type: 'number',
    },
    {
        field: 'Sandwich Count Vs. Ticket Count_z',
        headerName: 'Sandwich Count Vs. Ticket Count_z',
        type: 'number',
    },
    {
        field: 'Wheat Duplicates',
        headerName: 'Wheat Duplicates',
        type: 'number',
    },
    {
        field: 'Wheat Duplicates_z',
        headerName: 'Wheat Duplicates_z',
        type: 'number',
    },
];

export { columnsJJ };
