export const fetchData = async () => {
  try {
      const queryString = new URLSearchParams({ action: 'READ' }).toString();
      const response = await fetch(
          `https://r0qqwiem7g.execute-api.us-east-2.amazonaws.com/dev/status_summary?${queryString}`,
          {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json',
              },
          }
      );

      if (response.ok) {
          const data = await response.json();
          return data;
      } else {
          throw new Error(
              `Request failed with status code ${response.status}`
          );
      }
  } catch (error) { console.error(error); }
};