import React, { Component } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Admin } from './views/Admin';
import { Stores } from './components/Stores';
import { SignUp } from './components/Sign-Up';
import { MenuMapper } from './components/MenuMapper';
import { Emails } from './components/Emails';
import { RoutesURL } from './constants/routes';
import { Monitor } from './components/MonitoringBoard';
import { FileDownloader } from './components/FileDownloader';
import { OnboardingStatus } from './components/OnboardingStatus';

export class AppRouter extends Component {
    render() {
        return (
            <React.Fragment>
                <Box
                    width="100%"
                    overflow="hidden"
                    display="flex"
                    sx={{ alignItems: 'stretch' }}
                >
                    <Routes>
                        <Route index element={<Admin />} />
                        <Route path={RoutesURL.dashboard} element={<Admin />} />
                        <Route
                            path={RoutesURL.onboarding}
                            element={<Stores />}
                        />
                        <Route path={RoutesURL.signup} element={<SignUp />} />
                        <Route
                            path={RoutesURL.menuMapper}
                            element={<MenuMapper />}
                        />
                        <Route path={RoutesURL.emails} element={<Emails />} />
                        <Route
                            path={RoutesURL.monitorboard}
                            element={<Monitor />}
                        />
                        <Route
                            path={RoutesURL.onboardingstatus}
                            element={<OnboardingStatus />}
                        />
                        <Route
                            path={RoutesURL.filedownloader}
                            element={<FileDownloader />}
                        />
                        <Route
                            path={RoutesURL.notFound}
                            element={
                                <Navigate replace to={RoutesURL.dashboard} />
                            }
                        />
                    </Routes>
                </Box>
            </React.Fragment>
        );
    }
}
