import React, { useState, useEffect } from 'react';

// MUI's Component Library
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import { getDirectory, getFile } from '../../../api/index';

let today = new Date();
let tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);
let dd = String(today.getDate()).padStart(2, '0');
let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
let yyyy = today.getFullYear();
let dd_2 = String(tomorrow.getDate()).padStart(2, '0');
let mm_2 = String(tomorrow.getMonth() + 1).padStart(2, '0'); //January is 0!
let yyyy_2 = tomorrow.getFullYear();
let today_string = yyyy + '-' + mm + '-' + dd;
let tomorrow_string = yyyy_2 + '-' + mm_2 + '-' + dd_2;

function Emails(props: any) {
    const [selectedDate, setSelectedDate] = useState<string>(tomorrow_string);
    const [directory, setDirectory] = useState<any>({});
    const [concept, setConcept] = useState<string | false>(false);
    const [file, setFile] = useState<string>('');

    const handleAccordianChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setConcept(isExpanded ? panel : false);
        };

    useEffect(() => {
        setDirectory({});
        getDirectory(selectedDate).then((results) => {
            setDirectory(results);
            console.log(results);
        });
    }, [selectedDate]);

    return (
        <Box
            width="100%"
            display="flex"
            sx={{
                flexDirection: 'row',
                alignItems: 'stretch',
            }}
        >
            <Box
                width="calc(100% - 600px)"
                display="flex"
                sx={{ flexDirection: 'column' }}
            >
                <TextField
                    id="date"
                    label="Email Date"
                    type="date"
                    defaultValue={selectedDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(event) => setSelectedDate(event.target.value)}
                    sx={{ margin: 2, width: 220 }}
                />
                <Box sx={{ overflowY: 'auto', minHeight: '300px' }}>
                    {Object.keys(directory).length !== 0 ? (
                        Object.keys(directory)
                            .sort((a, b) =>
                                a.toLowerCase().localeCompare(b.toLowerCase())
                            )
                            .map((item: any, idx: any) => {
                                return (
                                    <Accordion
                                        expanded={concept === item}
                                        onChange={handleAccordianChange(item)}
                                        key={idx}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography
                                                sx={{
                                                    width: '33%',
                                                    flexShrink: 0,
                                                }}
                                            >
                                                {item}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {concept === item &&
                                                concept &&
                                                Object.keys(
                                                    directory[concept]
                                                ).map((file: any, idx: any) => {
                                                    return (
                                                        <Box
                                                            sx={{
                                                                mx: 2,
                                                                my: 0.5,
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'row',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                getFile(
                                                                    selectedDate,
                                                                    concept,
                                                                    file
                                                                ).then(
                                                                    (
                                                                        results
                                                                    ) => {
                                                                        directory[
                                                                            concept
                                                                        ][file]
                                                                            ? setFile(
                                                                                  results
                                                                                      .substring(
                                                                                          2,
                                                                                          results.length -
                                                                                              2
                                                                                      )
                                                                                      .replaceAll(
                                                                                          '\\n',
                                                                                          ''
                                                                                      )
                                                                              )
                                                                            : setFile(
                                                                                  results
                                                                              );
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            {concept &&
                                                            directory[concept][
                                                                file
                                                            ] ? (
                                                                <CheckCircleRoundedIcon />
                                                            ) : (
                                                                <DangerousRoundedIcon />
                                                            )}
                                                            <Typography mx={1}>
                                                                {concept &&
                                                                    file}
                                                            </Typography>
                                                        </Box>
                                                    );
                                                })}
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })
                    ) : (
                        <CircularProgress sx={{ mx: 2 }} />
                    )}
                </Box>
            </Box>
            <Box
                width="600px"
                display="flex"
                sx={{ flexDirection: 'column', alignContent: 'stretch' }}
            >
                {file && (
                    <iframe
                        title="Email Preview"
                        style={{
                            flexGrow: 1,
                            border: 'none',
                            borderLeft: '1px solid',
                            margin: 0,
                            padding: 0,
                        }}
                        data-type="text/html"
                        srcDoc={file}
                    ></iframe>
                )}
            </Box>
        </Box>
    );
}

export { Emails };
